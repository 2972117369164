.Modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0007;
  z-index: 10;
}

.ModalViewport {
  position: absolute;
  pointer-events: none;
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  overflow: auto;
  pointer-events: auto;
  background-color: #feffff;
}

.ModalContent {
  margin: 15px;
  pointer-events: none;
}
