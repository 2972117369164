.Checkbox {
  pointer-events: auto;
  margin-left: 30px;
}
.Checkbox input:disabled {
  color: #999;
}
.Checkbox .mui-checkbox>label {
  padding-left: 10px;
}
.Checkbox .mui-checkbox>label>input {
  top: 0pt;
}
