.Spinner {
  position: absolute;
  left: calc(50.0% + 0px - 24px);
  top: calc(-50px + 50.0%);
  width: 48px;
  height: 48px;
}

.circularProgressIndicator {
  position: relative;
  width: 50px;
  height: 50px;
  display: none;
}
.circularProgressIndicator-active {
  animation: rotate 2s linear infinite;
  display: inline;
}
.circularProgress-animatedPath {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  animation: circularProgress-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}
@keyframes circularProgress-dash{
  0%{
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
