/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'AvenirNext-Regular';
  src: url('fonts/AvenirNext-Regular.ttf');
}

.font-avenirNextRegular {
  font-family: 'AvenirNext-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('fonts/Montserrat-SemiBold.ttf');
}

.font-montserratSemiBold {
  font-family: 'Montserrat-SemiBold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'AvenirNext-DemiBold';
  src: url('fonts/AvenirNext-DemiBold.ttf');
}

.font-avenirNextDemiBold {
  font-family: 'AvenirNext-DemiBold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.App { /* Base font style */
  font-family: 'AvenirNext-Regular', sans-serif;
  font-size: 15.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4d585a;
  background-color: #feffff;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: 'AvenirNext-Regular', sans-serif;
  font-size: 15.2px;
}

.App .appBg {
  background-color: #feffff;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: #4d585a;
}

.App .secondaryTextColor {
  color: #fb9108;
}

.App .secondaryTextBg {
  background-color: #fb9108;
}

.App .primaryTextColor {
  color: #fda200;
}

.App .primaryBg {
  background-color: #fda200;
}

.App .highlightTextColor {
  color: #376183;
}

.App .highlightBg {
  background-color: #376183;
}

.App .unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.App .undraggable {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: 'AvenirNext-DemiBold', sans-serif;
  font-size: 14.2px;
  font-weight: 600;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: 'Montserrat-SemiBold', sans-serif;
  font-size: 19.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 19.0px;
}
