.Input {
  pointer-events: auto;
}
.Input input:disabled {
  color: #999;
}
.Input .mui-textfield>label {
  overflow: hidden;
}

.Input input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
.Input input[type=number]::-webkit-inner-spin-button, 
.Input input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}
