.Title {
  position: relative;
  margin-top: 75px;
  margin-left: 10.0%;
  margin-right: 10.0%;
  width: 80.0%;
}
.Title > * {
  width: 100%;
  font-size: 32px;
  color: #fda200;
  text-align: center;
}
