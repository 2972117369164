.Subtitle {
  position: relative;
  margin-top: 10px;
  margin-left: 10.0%;
  margin-right: 10.0%;
  margin-bottom: 30px;
  width: 80.0%;
}
.Subtitle > * {
  width: 100%;
  font-size: 24px;
  color: #4d5758;
  text-align: center;
}
