.Button {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  pointer-events: auto;
}

.Button > button {
  color: white;
  background-color: #376183;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
  cursor: pointer;  
}

.Button > button:active,
.Button > button:focus,
.Button > button:hover {
  color: white;
  background-color: #49718f;
}

.Button > button:focus {
  box-shadow: 0 0 5px rgba(53, 98, 131, 0.5000);
}
