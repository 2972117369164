.IconButton {
  display: flex;
  justify-content: space-evenly;
}

.IconButton > button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  width: auto;
  height: auto;
}

.IconButton > button > .IconButtonText {
  margin-top: 10px;
  cursor: pointer;
  pointer-events: auto;
  width: auto;
  height: auto;
}

.IconButton > button > .IconButtonIcon {
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  width: auto;
  height: auto;
}

.IconButton > button > .IconButtonIcon > img {
  display: block;
}

.IconButton > button:focus {
  outline: none;
}

.IconButton > button:active,
.IconButton > button:focus,
.IconButton > button:hover {
  border: none;
  filter: brightness(1.1);
}

.IconButton > button:focus > .IconButtonIcon {
  filter: drop-shadow(0 0 5px rgba(53, 98, 131, 0.5000));
}
.IconButton > button:focus > .IconButtonText {
  text-shadow: 0 0 5px rgba(53, 98, 131, 0.5000);
}
