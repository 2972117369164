.ErrorFallback {
  position: relative;
  margin-top: 15px;
  margin-left: 10.0%;
  margin-right: 10.0%;
  width: 80.0%;
}
.ErrorFallback > pre {
  width: 100%;
}
