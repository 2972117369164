.DataTable.mui-table {
  border-spacing: 0px;
  text-align: center;
  margin-left: 0%;
  margin-right: 0%;
}

table.DataTable>thead>tr>th {
  text-align: center;
  vertical-align: top;
  pointer-events: auto;
  background-color: #feffff;
  position: sticky;
  padding-left: 14pt;
  padding-right: 14pt;
  top: 0;
  z-index: 1;
}

table.DataTable>tbody>tr>td .Input>.mui-textfield {
  width: 100%;
  display: flex;
  justify-content: center;
}

table.DataTable>tbody>tr>td .Input>.mui-textfield>input {
  text-align: center;
}

.DataTable>tbody {
  pointer-events: auto;
}
.DataTable>tbody>tr:hover {
  background-color: #eee;
}
.DataTable>tbody>tr.rowSelected {
  background-color: #ccc;
}

.DataTable .Input>.mui-textfield,
.DataTable .Input>.mui-textfield>input {
  margin: 0;
  padding: 0;
  height: auto;
  border: none;
}

.DataTable .columnHeading {
  display: inline-block;
}

.DataTable .sortIndicator {
  display: inline-block;
  font-size: 14pt;
  position: absolute;
  padding-left: 3pt;
  top: 5.5pt;
}
