.AppScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  pointer-events: none;

  display: flex;
  flex-direction: column;
}

.AppScreenArea {
  flex: 1;
  position: relative;
  overflow: auto;
}

.AppScreenViewport {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  pointer-events: auto;
}

.AppScreenContent {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  pointer-events: none;
}

/*
@media (min-width: 568px) {
  .AppScreenContent {
    margin-left: 20%;
    margin-right: 20%;
  }
}
*/
