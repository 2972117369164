.Appbar {
  display: block;
  height: auto;
  width: calc(100% - 8px);
  padding: 4px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d5d5d5;
}

.Appbar img {
  height: 32px;
}

.Appbar .IconButton, .Appbar .Menu {
  min-width: 40px;
}
