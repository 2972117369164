.Menu {
  pointer-events: auto;
}

.Menu.mui-dropdown {
  display: flex;
  justify-content: center;
}

.Menu > .mui-btn {
  padding: 0;
  margin: 0;
  background: none;
  width: auto;
  height: auto;
}

.Menu > .mui-btn > img {
  display: block;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.Menu > .mui-btn:focus {
  outline: none;
}

.Menu > .mui-btn:active,
.Menu > .mui-btn:focus,
.Menu > .mui-btn:hover {
  background: none;
  border: none;
  filter: brightness(1.1);
  box-shadow: none;
}

.Menu > .mui-btn:focus > * {
  filter: drop-shadow(0 0 5px rgba(53, 98, 131, 0.5000));
  text-shadow: 0 0 5px rgba(53, 98, 131, 0.5000);
  outline: none;
}

.MenuItem {
  pointer-events: auto;
}

.MenuSpacer {
  margin-top: 5px;
  height: 5px;
  border-top: 1px solid #ccc;
}
