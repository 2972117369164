.DashboardIconRow {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.DashboardIcons .IconButton img {
  height: 80px;
}

.DashboardIcons .IconButton .IconButtonText {
  width: 120px;
}
