.Form {
  /*margin-left: 15px;
     margin-right: 15px;
     display: inline-block;*/
  text-align: left;
  /*border: solid black 5px;*/
}

/* Abbey Addition */


.Form input, .Form textarea, .Form select{
  position: relative;
  display: block;
  padding: .5rem .8rem .5rem .8rem;
  text-align: left; 
  /* position:relative for .prevBtn and .nextBtn position:absolute; changed margin from 0 and padding from 5*/
}

.FormButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.FormCancelButton {
  margin-right: 20px;
  margin-left: 20px;
}

.FormCancelButton > button {
  background-color: #999;
}

.FormSubmitButton {
  margin-left: 20px;
  margin-right: 20px;
}
